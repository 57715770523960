import { createApiAction } from 'utils/helpers';
import { IEventsPageState } from './Profile.interfaces';

export const GET_USERS_EVENTS = 'GET_USERS_EVENTS';
export const GET_USERS_EVENTS_SUCCESS = 'GET_USERS_EVENTS_SUCCESS';

export const POST_SET_NEW_PASSWORD = 'POST_SET_NEW_PASSWORD';

export const TOGGLE_SHARE = 'TOGGLE_SHARE';

export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';

export const GET_ZIPCODES = 'GET_ZIPCODES';

export const GET_USERS_BY_ID = 'GET_USERS_BY_ID';
export const GET_USERS_BY_ID_SUCCESS = 'GET_USERS_BY_ID_SUCCESS';

export const PUT_FAVORITE = 'PUT_FAVORITE';
export const PUT_FAVORITE_SUCCESS = 'PUT_FAVORITE_SUCCESS';

export const GET_CONTRIBUTORS = 'GET_CONTRIBUTORS';
export const GET_CONTRIBUTORS_SUCCESS = 'GET_CONTRIBUTORS_SUCCESS';

export const GET_PRODUCERS = 'GET_PRODUCERS';
export const GET_PRODUCERS_SUCCESS = 'GET_PRODUCERS_SUCCESS';

export const PATCH_UPDATE_USERINFO = 'PATCH_UPDATE_USERINFO';
export const PATCH_UPDATE_USERINFO_SUCCESS = 'PATCH_UPDATE_USERINFO_SUCCESS';

export const PATCH_UPDATE_ADMIN_INFO = 'PATCH_UPDATE_ADMIN_INFO';
export const PATCH_UPDATE_ADMIN_INFO_SUCCESS = 'PATCH_UPDATE_ADMIN_INFO_SUCCESS';

export const PATCH_UPDATE_USER_IMAGE = 'PATCH_UPDATE_USER_IMAGE';
export const PATCH_UPDATE_USER_IMAGE_SUCCESS = 'PATCH_UPDATE_USER_IMAGE_SUCCESS';

export const GET_CARD_HISTORY = 'GET_CARD_HISTORY';
export const GET_CARD_HISTORY_SUCCESS = 'GET_CARD_HISTORY_SUCCESS';

export const GET_GENERAL_STATS = 'GET_GENERAL_STATS';
export const GET_GENERAL_STATS_SUCCESS = 'GET_GENERAL_STATS_SUCCESS';

export const GET_PLAN_STATS = 'GET_PLAN_STATS';
export const GET_PLAN_STATS_SUCCESS = 'GET_PLAN_STATS_SUCCESS';

export const GET_PAYMENT_STATS = 'GET_PAYMENT_STATS';
export const GET_PAYMENT_STATS_SUCCESS = 'GET_PAYMENT_STATS_SUCCESS';

export const GET_ZONE_STATS = 'GET_ZONE_STATS';
export const GET_ZONE_STATS_SUCCESS = 'GET_ZONE_STATS_SUCCESS';

export const GET_PRODUCER_REDACTOR_STATS = 'GET_PRODUCER_REDACTOR_STATS';
export const GET_PRODUCER_REDACTOR_STATS_SUCCESS = 'GET_PRODUCER_REDACTOR_STATS_SUCCESS';

export const POST_CUSTOMER_PORTAL = 'POST_CUSTOMER_PORTAL';
export const POST_CUSTOMER_PORTAL_SUCCESS = 'POST_CUSTOMER_PORTAL_SUCCESS';

export const GET_ONE_INVOICE = 'GET_ONE_INVOICE';
export const GET_ONE_INVOICE_SUCCESS = 'GET_ONE_INVOICE_SUCCESS';

export const SAVE_NOTIFICATION_SUBSCRIPTION = 'SAVE_NOTIFICATION_SUBSCRIPTION';

export const TRANSCRIBE_AUDIO = 'TRANSCRIBE_AUDIO';
export const TRANSCRIBE_AUDIO_SUCCESS = 'TRANSCRIBE_AUDIO_SUCCESS';

export const SET_ACCEPTED_TERMS = 'SET_ACCEPTED_TERMS';
export const SET_ACCEPTED_TERMS_SUCCESS = 'SET_ACCEPTED_TERMS_SUCCESS';

export const LIST_MY_FAVORITES = 'LIST_MY_FAVORITES';
export const LIST_MY_FAVORITES_SUCCESS = 'LIST_MY_FAVORITES_SUCCESS';

export const CLEAR_PRODUCER_REDACTOR_STATS = 'CLEAR_PRODUCER_REDACTOR_STATS';
export const CLEAR_CONTRIBUTOR_OPTIONS = 'CLEAR_CONTRIBUTOR_OPTIONS';
export const CLEAR_ONE_INVOICE = 'CLEAR_ONE_INVOICE';
export const SUBSCRIBE_TO_PUSH = 'SUBSCRIBE_TO_PUSH';
export const CLEAR_TRANSCRIPTION = 'CLEAR_TRANSCRIPTION';
export const clearTranscription = (languageCode: string) => {
  return {
    type: CLEAR_TRANSCRIPTION,
    payload: { languageCode: languageCode },
  };
};
export const GET_ENTITY_PLAN = 'GET_ENTITY_PLAN';
export const GET_ENTITY_PLAN_SUCCESS = 'GET_ENTITY_PLAN_SUCCESS';
export const UPDATE_ENTITY_PLAN = 'UPDATE_ENTITY_PLAN';
export const UPDATE_ENTITY_PLAN_SUCCESS = 'UPDATE_ENTITY_PLAN_SUCCESS';
export const SET_EVENTS_PAGE_STATE = 'SET_EVENTS_PAGE_STATE';
export const setEventsPageState = (state: IEventsPageState) => {
  return {
    type: SET_EVENTS_PAGE_STATE,
    payload: state,
  };
};

export const GET_MY_ENTITY = 'GET_MY_ENTITY';
export const GET_MY_ENTITY_SUCCESS = 'GET_MY_ENTITY_SUCCESS';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';

export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const REGENERATE_SECRET_KEY = 'REGENERATE_SECRET_KEY';
export const REGENERATE_SECRET_KEY_SUCCESS = 'REGENERATE_SECRET_KEY_SUCCESS';
export const UPDATE_WIDGET_STYLES = 'UPDATE_WIDGET_STYLES';
export const UPDATE_WIDGET_STYLES_SUCCESS = 'UPDATE_WIDGET_STYLES_SUCCESS';

export const getUsersEvents = createApiAction(GET_USERS_EVENTS);
export const toggleShareEvent = createApiAction(TOGGLE_SHARE);
export const getTags = createApiAction(GET_TAGS);
export const getUsersById = createApiAction(GET_USERS_BY_ID);
export const putFavorite = createApiAction(PUT_FAVORITE);
export const getContributors = createApiAction(GET_CONTRIBUTORS);
export const getProducers = createApiAction(GET_PRODUCERS);
export const patchUpdateUserInfo = createApiAction(PATCH_UPDATE_USERINFO);
export const patchUpdateAdminInfo = createApiAction(PATCH_UPDATE_ADMIN_INFO);
export const patchUpdateUserFiles = createApiAction(PATCH_UPDATE_USER_IMAGE);
export const getCardHistory = createApiAction(GET_CARD_HISTORY);
export const postCustomerPortal = createApiAction(POST_CUSTOMER_PORTAL);
export const getOneInvoice = createApiAction(GET_ONE_INVOICE);
export const saveNotificationSubscription = createApiAction(SAVE_NOTIFICATION_SUBSCRIPTION);
export const transcribeAudio = createApiAction(TRANSCRIBE_AUDIO);
export const setAcceptedTerms = createApiAction(SET_ACCEPTED_TERMS);
export const getEntityPlan = createApiAction(GET_ENTITY_PLAN);
export const updateEntityPlan = createApiAction(UPDATE_ENTITY_PLAN);
export const getMyEntity = createApiAction(GET_MY_ENTITY);
export const updateEntity = createApiAction(UPDATE_ENTITY);
export const listMyFavorites = createApiAction(LIST_MY_FAVORITES);
export const regenerateSecret = createApiAction(REGENERATE_SECRET_KEY);
export const updateWidgetStyles = createApiAction(UPDATE_WIDGET_STYLES);
