import styled from 'styled-components';

export const BrandingContainer = styled.div`
  width: 100%;
  padding: 0 25px;

  .footer {
    justify-content: end;
  }
`;
