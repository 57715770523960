import { axiosInstance } from 'services/api';
import { IDetailedEvent, IEntity, IFilterUsers, ITag, IUser, IWidgetStyles } from 'interfaces';
import { UserRoles } from 'constant';
import { IEntityPlan } from './Profile.interfaces';

const config = {
  headers: {
    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary1vA2ZEJbz87424sN',
    'Sec-Fetch-Mode': 'cors',
  },
};

export default {
  async getUsersEvents(payload?: { ordering?: string; page?: number }) {
    return axiosInstance.get<IDetailedEvent[]>('event/contributor-event/', { params: payload });
  },
  async toggleShareEvent(id: number, share: boolean) {
    return axiosInstance.patch(`event/${id}/share/`, { share: share });
  },
  async getTags(query: { ordering?: string; page?: number; ids?: string }) {
    return axiosInstance.get<ITag[]>('event/tag/', { params: query });
  },
  async getUsersById(payload: { id: string }) {
    return axiosInstance.post('contibuter/by-ids/', payload);
  },
  async putFavorite(data: number) {
    return axiosInstance.put(`favorite/${data}/`);
  },
  async getContributors(query: IFilterUsers) {
    return axiosInstance.get<IUser[]>('entity/', { params: query });
  },
  async getProducers(query: IFilterUsers) {
    return axiosInstance.get<IUser[]>('user/', { params: { ...query, role: UserRoles.PRODUCER } });
  },
  async patchUpdateUserInfo(data: IUser) {
    return axiosInstance.patch<IUser>(`user/${data.id}/`, data);
  },
  async patchUpdateAdminInfo(data: IUser) {
    return axiosInstance.patch<IUser>(`user/${data.id}/`, data);
  },
  async patchUpdateUserFiles(payload: { form: FormData; id: number }) {
    return axiosInstance.patch<IUser>(`user/${payload.id}/upload-file/`, payload.form, config);
  },
  async getCardHistory(payload: { page: number; id?: number }) {
    return axiosInstance.get(`payment/invoices/`, {
      params: { page: payload.page, entity: payload.id },
    });
  },
  async postCustomerPortal() {
    return axiosInstance.post('/payment/get_customer_portal/');
  },
  async getOneInvoice(payload: number) {
    return axiosInstance.get(`/payment/invoices/${payload}/`);
  },
  async saveNotificationSubscription(payload: { sub: PushSubscriptionJSON }) {
    return axiosInstance.post(`/notifications_subscribe/`, payload);
  },
  async transcribeAudio(payload: FormData) {
    return axiosInstance.post(`/event/recognise_speech/`, payload, config);
  },
  async setAcceptedTerms(id: number) {
    return axiosInstance.get(`user/${id}/accepted-terms/`);
  },
  async getEntityPlan(id: number) {
    return axiosInstance.get(`entity/${id}/plan/`);
  },
  async updateEntityPlan(payload: IEntityPlan) {
    return axiosInstance.patch(`entity/${payload.entity_id}/plan/`, payload);
  },
  async getMyEntity() {
    return axiosInstance.get(`entity/my-entity/`);
  },
  async updateEntity(payload: { id: number; data: FormData | IEntity }) {
    return axiosInstance.patch(`entity/${payload.id}/`, payload.data);
  },
  async listMyFavorites() {
    return axiosInstance.get('favorite/');
  },
  async regenerateSecret() {
    return axiosInstance.get('entity/regenerate-secret/');
  },
  async updateWidgetStyles(payload: IWidgetStyles) {
    return axiosInstance.patch('entity/widget-styles/', payload);
  },
};
