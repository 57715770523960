import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TagManager from 'react-gtm-module';
import store from './services/store';
import { Routes } from './Routes';
import GlobalStyles from 'assets/styles/Global';
import './i18n';
import 'dayjs/locale/fr';
import 'moment/locale/fr-ch';
import { getUserLanguage, setAntDLocale } from 'utils/helpers';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import './assets/fonts/fonts.css';
import { Router } from 'react-router-dom';
import { history } from 'utils/history';
import ThemeHandler from './assets/styles/ThemeHandler';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);

const language = getUserLanguage();
dayjs.locale(language.lng) && moment.locale(language.lng);

const tagManagerArgs = {
  gtmId: 'GTM-KGCD776',
};
TagManager.initialize(tagManagerArgs);
const isProduction = process.env.NODE_ENV === 'production';

const App: FC = () => {
  return (
    <CacheBuster currentVersion={version} isEnabled={isProduction} isVerboseMode={false} metaFileDirectory={'.'}>
      <Provider store={store}>
        <ThemeHandler>
          <GlobalStyles />
          <ConfigProvider locale={setAntDLocale()}>
            <Elements stripe={stripePromise}>
              <Router history={history}>
                <Routes />
              </Router>
            </Elements>
          </ConfigProvider>
        </ThemeHandler>
      </Provider>
    </CacheBuster>
  );
};

export default withTranslation()(App);
