import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AntoPicto from '../../../assets/images/logo_picto.svg';
import { PoweredBy } from '../../../modules/Auth/Auth.style';
import { BrandingContainer } from './Branding.style';

const Branding: FC = () => {
  const { t } = useTranslation();
  return (
    <BrandingContainer>
      <PoweredBy className={'footer'}>
        <span>
          {t('powered_by') + ' '} <strong>anto.info</strong>
        </span>
        <img src={AntoPicto} />
      </PoweredBy>
    </BrandingContainer>
  );
};

export default Branding;
