import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../modules/Global/Global.interfaces';
import defaultTheme, { applyThemeOverrides } from './index';
import { ThemeProvider } from 'styled-components';
import { EventsState } from '../../modules/Events/Event.interfaces';

interface IframeThemeHandlerProps {
  children: React.ReactNode;
}

const ThemeHandler: FC<IframeThemeHandlerProps> = ({ children }) => {
  const { iframeParams, contributorInfo } = useSelector(
    ({ globalReducer, eventsReducer }: { globalReducer: GlobalState; eventsReducer: EventsState }) => ({
      iframeParams: globalReducer?.iframeParams,
      contributorInfo: eventsReducer?.contributorInfo,
    }),
  );

  const theme = useMemo(() => {
    if (iframeParams?.isIframe && contributorInfo?.widget_styles) {
      const overrides = {
        primary: [
          contributorInfo.widget_styles.secondary_color || defaultTheme.primary[0],
          ...defaultTheme.primary.slice(1, 5),
          contributorInfo.widget_styles.primary_color || defaultTheme.primary[5],
          ...defaultTheme.primary.slice(6),
        ],
      };
      return applyThemeOverrides(defaultTheme, overrides);
    }
    return defaultTheme;
  }, [iframeParams?.isIframe, contributorInfo]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeHandler;
